import { useAppQuery } from './useAppQuery';

const useEDDDocuments = (userId) => {
  const { data, isLoading } = useAppQuery(`edd-documents_${userId}`, {
    url: `v1/users/${userId}/edd`,
    method: 'GET',
  });

  return { data, isLoading };
};

export default useEDDDocuments;
