import * as Sentry from '@sentry/browser';

const ErrorBoundary = ({ error }) => {
  if (error) {
    Sentry.captureException(error);
  }

  return (
    <div className="my-5">
      <p>There was an error in loading this component.</p>
    </div>
  );
};

export default ErrorBoundary;
