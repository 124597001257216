import { Fragment } from 'react';
import type { CSSProperties, ReactNode } from 'react';
import TableHeader from './TableHeader';
import type { SortDirection, TableColumn } from './TableHeader';

type TableProps<T> = {
  columns: TableColumn[];
  data: T[];
  onSort?: (field: string, direction: SortDirection) => void;
  renderRow: (item: T) => ReactNode;
};

const Table = <T,>({ columns, data, onSort, renderRow }: TableProps<T>) => {
  const columnIncludesAction = columns.find(
    (column) => column.label === 'Action'
  );

  const gridColumns = columnIncludesAction
    ? 'grid-cols-[repeat(calc(var(--length)-1),minmax(min-content,1fr))_max-content]'
    : `grid-cols-[repeat(var(--length),minmax(min-content,1fr))]`;

  return (
    <section
      className={`grid ${gridColumns} hide-scroll-bar overflow-x-scroll whitespace-nowrap`}
      style={{ '--length': columns.length } as CSSProperties}
    >
      <TableHeader columns={columns} onSort={onSort} />
      {data.map((item, index) => (
        <Fragment key={index}>{renderRow(item)}</Fragment>
      ))}
    </section>
  );
};

export default Table;
