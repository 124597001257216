import { ArrowDown, ArrowLeft, ArrowRight } from '@kudyfinancials/livre';
import Dropdown from './Ui/Dropdown';
import type { Dispatch, SetStateAction } from 'react';

type Option = {
  label: number;
  value: number;
};

type PaginationProps = {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  hasMorePages: boolean;
  limit?: number;
  setLimit?: Dispatch<SetStateAction<number>>;
};

const Pagination = ({
  page,
  setPage,
  hasMorePages,
  limit,
  setLimit,
}: PaginationProps) => {
  const disablePrevious = page === 0;
  const disableNext = !hasMorePages;

  return (
    <div className="my-4 w-full flex flex-col items-center md:flex-row md:justify-between">
      <div className="flex justify-between flex-1 md:mr-4 w-full gap-4 md:w-auto">
        <button
          className="flex items-center gap-2 disabled:text-loblolly disabled:pointer-events-none group"
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={disablePrevious}
        >
          <ArrowLeft />
          <span className="text-sm group-hover:underline">Previous</span>
        </button>
        <button
          className="flex items-center gap-2 disabled:text-loblolly disabled:pointer-events-none group"
          onClick={() => setPage((prev) => prev + 1)}
          disabled={disableNext}
        >
          <span className="text-sm group-hover:underline">Next</span>
          <ArrowRight />
        </button>
      </div>
      {setLimit && (
        <div className="flex items-center self-end gap-2 mt-4 md:mt-0">
          <p className="text-sm text-shuttlegray">Results per page</p>
          <div className="border-0.5 border-loblolly rounded-sm px-1.5 py-1">
            <Dropdown
              icon={() => <ArrowDown width={16} height={16} />}
              selected={<p className="text-sm mr-1.5">{limit}</p>}
              options={[
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
              ]}
              onChange={(option: Option) => setLimit(option.value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Pagination;
