/**
 * Dash Component
 * @returns jsx
 */
const Dash = ({ color = '#283445' }) => {
  return (
    <svg
      width="20"
      height="2"
      viewBox="0 0 20 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline mr-2.5"
    >
      <path d="M0 1H20" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default Dash;
