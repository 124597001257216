export const customStyles = {
  valueContainer: (provided) => {
    const paddingTop = '0.1rem';
    const paddingBottom = '0.1rem';

    return {
      ...provided,
      paddingTop,
      paddingBottom,
    };
  },

  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorSeparator: (provided) => {
    return { ...provided, display: 'none' };
  },
  dropdownIndicator: (provided) => {
    return { ...provided, color: '#0C0C0D' };
  },
  indicatorsContainer: (provided, state) => {
    const color = state?.selectProps?.errored ? '#B85050' : '#0C0C0D';
    const marginRight = state?.selectProps?.errored ? '.25em' : '';
    return { ...provided, color, marginRight };
  },
  placeholder: (provided) => {
    return { ...provided, color: '#717175' };
  },
  option: (provided, state) => {
    const bgColor = state.isFocused ? '#E3FBEA' : 'transparent';
    return {
      ...provided,
      backgroundColor: bgColor,
      color: '',
      '&:active': {
        backgroundColor: '#E3FBEA',
      },
    };
  },
  control: (provided, state) => {
    const borderColor = state.isFocused ? '#000000' : '#000000';
    return {
      ...provided,
      borderRadius: '0.125rem',
      border: `1px solid ${borderColor} !important`,
      backgroundColor: 'transparent',
      outline: 'none',
    };
  },
  singleValue: (provided, state) => {
    const color = state?.selectProps?.theme === 'dark' ? '#ffffff' : '0C0C0D';
    return {
      ...provided,
      color,
    };
  },
};
