import { createContext, useEffect, useReducer, useContext } from 'react';
import { getToken } from '../utils/localStorage';
import userReducer from './reducers/user';
import { FullPageLoader } from '@kudyfinancials/livre';
import createAxiosInstance from '@/utils/createAxiosInstance';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const initialState = {
  error: null,
  token: '',
  authenticating: true,
  loggedIn: false,
  user: null,
  isLoading: false,
};

const AuthContext = createContext();

const setLoading = (payload) => ({
  type: 'SET_LOADING',
  payload,
});

function AuthProvider(props) {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getToken();
    const userData = JSON.parse(localStorage.getItem('userData')) || null;

    if (!token) {
      dispatch({ type: 'LOGGED_OUT' });
      navigate('/login');
      return;
    }

    dispatch({
      type: 'LOGGED_IN',
      payload: {
        token,
        user: userData,
      },
    });
  }, [navigate]);

  if (state.authenticating) {
    return <FullPageLoader />;
  }

  const login = async (credentials, location, navigate) => {
    try {
      dispatch(setLoading(true));
      const { data } = await createAxiosInstance().post(
        'v1/login',
        credentials
      );

      localStorage.setItem('jwtToken', data.token);
      localStorage.setItem('userData', JSON.stringify(data.data));

      dispatch(setLoading(false));
      dispatch({
        type: 'LOGGED_IN',
        payload: {
          token: data.token,
          user: data.data,
        },
      });

      navigate(location.state?.from ? location.state.from : '/overview');
    } catch (error) {
      dispatch(setLoading(false));
      let err;
      if (error.response) {
        err = error.response.data.message;
      } else if (error.request) {
        err = 'Problem with request.';
      } else {
        err = error.message;
      }

      dispatch({
        type: 'SET_AUTH_ERROR',
        payload: { error: err },
      });
    }
  };

  const setUserData = (data) => {
    localStorage.setItem('userData', JSON.stringify(data));
    dispatch({
      type: 'SET_USER',
      payload: { ...data },
    });
  };

  const logout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('userData');
    localStorage.removeItem('_expiredTime');
    queryClient.clear();

    dispatch({
      type: 'LOGGED_OUT',
    });
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      value={{ auth: state, dispatch, login, logout, setUserData }}
      {...props}
    />
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
