import { ArrowRight } from '@kudyfinancials/livre';
import { useLocation, Link } from 'react-router-dom';

const BreadcrumbItem = ({ isActive, url, text }) => (
  <li
    className={`${
      isActive ? 'text-ebonyclay' : 'text-uberblue hover:text-ebonyclay'
    }`}
  >
    <Link to={url} className="capitalize">
      {text}
    </Link>
  </li>
);

const Breadcrumb = () => {
  const { pathname } = useLocation();

  const currentPath = pathname.split('/')[1];
  const isOverview = pathname === '/overview';

  const formatPathname = (pathname) => {
    return pathname
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <nav>
      <ol className="flex items-center space-x-2">
        <BreadcrumbItem isActive={isOverview} url="/overview" text="Overview" />
        {!isOverview && (
          <>
            <ArrowRight />
            <BreadcrumbItem
              isActive={true}
              url={`/${currentPath}`}
              text={formatPathname(currentPath)}
            />
          </>
        )}
      </ol>
    </nav>
  );
};
export default Breadcrumb;
