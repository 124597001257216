import { useModal } from '../context/ModalContext';
import CloseButton from './CloseButton';

const ModalHeader = ({ title }) => {
  const { handleModal } = useModal();
  return (
    <div className="flex justify-between py-10">
      <p className="font-lato text-2xl font-bold">{title}</p>
      <button
        className="block focus:outline-none text-2xl sm:text-4xl cursor-pointer"
        onClick={() => handleModal()}
      >
        <CloseButton />
      </button>
    </div>
  );
};

export default ModalHeader;
