import { styled } from 'styled-components';

const StyledCheckboxInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--form-background);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 0.9em;
  height: 0.9em;
  border: 1px solid #bcc2cc;
  border-radius: 2px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  &::before {
    content: '';
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(4% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1px 1px var(--form-control-color);
    background-color: #26c07f;
  }
  &:checked::before {
    transform: scale(1);
  }
  &:checked {
    border: 1px solid #26c07f;
  }
  &:focus {
    outline-offset: max(2px, 0.15em);
  }
  &:disabled {
    --form-control-color: var(--form-control-disabled);
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
`;

const NewCheckbox = ({ label, checked, onChange }) => {
  const handleCheckboxChange = () => {
    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <label className="flex items-center cursor-pointer">
      <StyledCheckboxInput
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <span className="ml-2 text-14 text-ebonyclay">{label}</span>
    </label>
  );
};

export default NewCheckbox;
