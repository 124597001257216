type Currency = 'USD' | 'NGN';

const formatters = (
  decimalPoints: number | undefined,
  currency: Currency | undefined
) => {
  const formatOptions = {
    style: 'currency',
    currency: currency ?? 'USD',
    ...(Number.isInteger(decimalPoints) && {
      minimumFractionDigits: decimalPoints,
    }),
  } satisfies Intl.NumberFormatOptions;

  const formatOptionsCompact = {
    notation: 'compact',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    roundingMode: 'trunc',
  } satisfies Intl.NumberFormatOptions;

  const locale = currency === 'NGN' ? 'en-NG' : 'en-US';

  return {
    standard: new Intl.NumberFormat(locale, formatOptions),
    compact: new Intl.NumberFormat(locale, {
      ...formatOptions,
      ...formatOptionsCompact,
    }),
  };
};

type FormatMoneyOptions = {
  decimalPoints?: number;
  shouldRoundDown?: boolean;
  roundDownAmount?: number;
};

const formatMoney = (
  money: string | number = 0,
  currency: Currency | undefined,
  options: FormatMoneyOptions = {}
) => {
  const {
    decimalPoints,
    shouldRoundDown = options.roundDownAmount || false,
    roundDownAmount = 999_999,
  } = options;

  const amount = Number(money);
  const { standard, compact } = formatters(decimalPoints, currency);
  const roundDown = shouldRoundDown && amount > roundDownAmount;

  return roundDown ? compact.format(amount) : standard.format(amount);
};

export default formatMoney;
