import { useModal } from '../../context/ModalContext';
import CloseButton from '../CloseButton';

const ModalBase = ({ children, title }) => {
  const { handleModal } = useModal();
  return (
    <div className="mx-4 my-10 font-lato">
      <div className="flex justify-between items-center mb-8">
        <h1 className="capitalize font-bold text-2xl text-ebonyclay">
          {title}
        </h1>
        <div
          className="text-2xl md:text-4xl cursor-pointer"
          onClick={() => handleModal()}
        >
          <CloseButton />
        </div>
      </div>
      {children}
    </div>
  );
};

export default ModalBase;
