import { useState } from 'react';
import NoResult from '@/components/Ui/NoResult';
import Contract from '@/utils/assets/Contract';
import { useAppQuery } from '../../hooks/useAppQuery';
import Statistics from '../../components/Statistics';
import PendingTasks from './PendingTasks';
import Table from '@/components/Table';
import { Loader } from '@kudyfinancials/livre';
import Pagination from '@/components/Pagination';

const tableHeadings = [
  { label: 'Date Uploaded' },
  { label: 'User Profiles' },
  { label: 'Email' },
  { label: 'Activity' },
  { label: 'Document' },
  { label: 'Status' },
  { label: 'Action' },
];

const Overview = () => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const { data: stats = {}, isLoading: statsLoading } = useAppQuery(
    ['general-stats'],
    {
      url: `v1/accounts/stats`,
      method: 'GET',
    }
  );

  const { data: tasks, isLoading: tasksLoading } = useAppQuery(
    ['pending-tasks', page, limit],
    {
      url: `/v1/accounts/tasks?limit=${limit}&offset=${page}`,
      method: 'GET',
    }
  );

  const pendingTasks = tasks?.data?.data ?? [];
  const hasMorePages = tasks?.data?.next || false;
  const isLoading = statsLoading || tasksLoading;

  if (isLoading) return <Loader />;

  const headings = `text-2xl font-bold`;
  const mutedTexts = `mt-1 text-shuttlegray`;

  const displayVerificationTask = (taskDetails) => {
    const { id, created_at, fullname, email, source, user_id, kycDocument } =
      taskDetails;

    const activity = source === 'KYC' ? 'KYC Documents' : 'EDD Form';
    const status =
      source === 'KYC' ? 'Pending Verification' : 'Ready for review';

    const document =
      source === 'EDD'
        ? {
            documentUrl: `/edd?userId=${user_id}&eddId=${id}`,
            documentText: 'View Document',
          }
        : {
            documentUrl: kycDocument,
            documentText: kycDocument ? 'View Document' : null,
          };

    return (
      <PendingTasks
        id={id}
        userId={user_id}
        dateUploaded={created_at}
        userProfile={fullname}
        email={email}
        activity={activity}
        status={status}
        {...document}
      />
    );
  };

  return (
    <section className="mx-4 mb-16 mt-80 sm:mb-[128px] lg:mx-8 xl:mt-7">
      <h1 className={`text-balance ${headings}`}>
        Welcome to Kudy’s Control Lane
      </h1>
      <p className={`${mutedTexts}`}>
        Here you have access to everything happening on Fora, and you can
        control client activities.
      </p>

      <Statistics statistics={stats} />

      <div>
        <h1 className={headings}>Pending Tasks</h1>
        <p className={`${mutedTexts} mb-2`}>
          Approve KYC documents or EDD forms
        </p>
        {pendingTasks.length > 0 ? (
          <>
            <Table
              columns={tableHeadings}
              data={pendingTasks}
              renderRow={displayVerificationTask}
            />
            <Pagination
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              hasMorePages={hasMorePages}
            />
          </>
        ) : (
          <NoResult
            className="mt-10"
            icon={<Contract className="text-loblolly" />}
            noResultText="You don't have any pending tasks yet."
          />
        )}
      </div>
    </section>
  );
};

export default Overview;
