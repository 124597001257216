import type { User } from '@kudyfinancials/livre';

type AuthError = {
  error: string;
};

type UserState = {
  token: string;
  user: User | null;
  loggedIn: boolean;
  authenticating: boolean;
  isLoading: boolean;
  error: AuthError | null;
};

type UserAction =
  | { type: 'LOGGED_IN'; payload: { token: string; user: User } }
  | { type: 'LOGGED_OUT'; payload: Record<string, never> }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_AUTH_ERROR'; payload: AuthError }
  | { type: 'SET_USER'; payload: User }
  | { type: 'CLEAR_AUTH_ERROR'; payload: Record<string, never> };

const userReducer = (
  state: UserState = {
    token: '',
    user: null,
    loggedIn: false,
    authenticating: false,
    isLoading: false,
    error: null,
  },
  action: UserAction
): UserState => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        loggedIn: true,
        authenticating: false,
      };
    case 'LOGGED_OUT':
      return {
        ...state,
        token: '',
        user: null,
        loggedIn: false,
        authenticating: false,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_AUTH_ERROR':
      return {
        ...state,
        error: action.payload,
        authenticating: false,
        loggedIn: false,
        user: null,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'CLEAR_AUTH_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default userReducer;
