export const Exchange = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0015 5H11.5015C12.33 5 13.0015 5.67157 13.0015 6.5C13.0015 6.77614 12.7777 7 12.5015 7C12.2254 7 12.0015 6.77614 12.0015 6.5C12.0015 6.22386 11.7777 6 11.5015 6H6.50154C6.2254 6 6.00154 6.22386 6.00154 6.5V7.6327C6.00154 7.88153 6.1845 8.09249 6.43083 8.12768L11.7137 8.88237C12.4526 8.98794 13.0015 9.62082 13.0015 10.3673V11.5C13.0015 12.3284 12.33 13 11.5015 13H11.0015V13.5C11.0015 13.7761 10.7777 14 10.5015 14C10.2254 14 10.0015 13.7761 10.0015 13.5V13H8.00154V13.5C8.00154 13.7761 7.77768 14 7.50154 14C7.2254 14 7.00154 13.7761 7.00154 13.5V13H6.50154C5.67311 13 5.00154 12.3284 5.00154 11.5C5.00154 11.2239 5.2254 11 5.50154 11C5.77768 11 6.00154 11.2239 6.00154 11.5C6.00154 11.7761 6.2254 12 6.50154 12H11.5015C11.7777 12 12.0015 11.7761 12.0015 11.5V10.3673C12.0015 10.1185 11.8186 9.90751 11.5723 9.87232L6.28941 9.11763C5.55043 9.01206 5.00154 8.37918 5.00154 7.6327V6.5C5.00154 5.67157 5.67311 5 6.50154 5H7.00154V4.5C7.00154 4.22386 7.2254 4 7.50154 4C7.77768 4 8.00154 4.22386 8.00154 4.5V5H10.0015V4.5C10.0015 4.22386 10.2254 4 10.5015 4C10.7777 4 11.0015 4.22386 11.0015 4.5V5ZM0.00153956 9C0.00153956 4.02944 4.03098 0 9.00154 0C13.9721 0 18.0015 4.02944 18.0015 9C18.0015 13.9706 13.9721 18 9.00154 18C7.35462 18 5.77212 17.5564 4.39066 16.7307L0.659654 17.9743C0.23706 18.1152 -0.144886 17.6748 0.0543259 17.2764L1.61844 14.1482C0.57304 12.6519 0.00153956 10.8686 0.00153956 9ZM4.2959 15.7082C4.43851 15.6606 4.59494 15.6798 4.72188 15.7603C5.99164 16.5659 7.46389 17 9.00154 17C13.4198 17 17.0015 13.4183 17.0015 9C17.0015 4.58172 13.4198 1 9.00154 1C4.58326 1 1.00154 4.58172 1.00154 9C1.00154 10.7565 1.5685 12.4257 2.60105 13.8002C2.71489 13.9517 2.73326 14.1546 2.6485 14.3241L1.48859 16.6439L4.2959 15.7082Z"
      fill="currentColor"
    />
  </svg>
);
