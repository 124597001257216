import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Notification from '../components/Notification';

import Error from '../utils/assets/Error';
import Success from '../utils/assets/Success';

/**
 * Toast component
 * @param {obj} opt option containing messgae and type
 */
export const Toast = (opt) => {
  const { type, message } = opt;
  let options = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    icon: type === 'error' ? <Error /> : <Success />,
  };

  if (opt.type === 'error') {
    toast.error(<Notification message={message} />, options);
  } else {
    toast.success(<Notification message={message} />, options);
  }
};
