import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../context/Auth';
import Layout from './Layout';

const PrivateRoutes = () => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default PrivateRoutes;
