import { jwtDecode } from 'jwt-decode';

type DecodedToken = {
  exp: number;
};

const isTokenExpired = (token: string) => {
  const { exp } = jwtDecode<DecodedToken>(token);
  const isExpired = exp < Date.now() / 1000;
  if (isExpired) {
    localStorage.removeItem('jwtToken');
  }
  return isExpired;
};

export const getToken = () => {
  const token = localStorage.getItem('jwtToken');
  return token && !isTokenExpired(token) ? token : null;
};
