export const ClientProfile = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.86872 9.2988L1.81349 11.3862C1.31396 11.6434 1 12.1581 1 12.7199V13.5C1 14.3284 1.67158 15 2.50007 15C2.77621 15 3.00007 15.2239 3.00007 15.5C3.00007 15.7761 2.77621 16 2.50007 16C1.1193 16 0 14.8807 0 13.5V12.7199C0 11.7835 0.523264 10.9257 1.35581 10.4971L4.71356 8.76871C3.64187 7.83429 3 6.47231 3 5C3 2.23858 5.23858 0 8 0C9.67659 0 11.2132 0.831947 12.138 2.19255C12.2932 2.42094 12.2339 2.73191 12.0055 2.88714C11.7771 3.04236 11.4661 2.98305 11.3109 2.75466C10.5704 1.66515 9.34186 1 8 1C5.79086 1 4 2.79086 4 5C4 6.41171 4.7368 7.69567 5.92027 8.41759C6.15601 8.5614 6.23054 8.86908 6.08674 9.10482C6.03291 9.19307 5.95611 9.25873 5.86872 9.2988ZM9.96127 13.5668L5.8793 15.4223C5.3438 15.6657 5 16.1996 5 16.7878V17.5C5 18.3284 5.67157 19 6.5 19H17.5C18.3284 19 19 18.3284 19 17.5V16.7878C19 16.1996 18.6562 15.6657 18.1207 15.4223L14.0387 13.5668C13.4161 13.8452 12.7261 14 12 14C11.2739 14 10.5839 13.8452 9.96127 13.5668ZM8.92216 12.9407C7.75209 12.0255 7 10.6006 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9C17 10.6006 16.2479 12.0255 15.0778 12.9407L18.5345 14.5119C19.427 14.9176 20 15.8075 20 16.7878V17.5C20 18.8807 18.8807 20 17.5 20H6.5C5.11929 20 4 18.8807 4 17.5V16.7878C4 15.8075 4.57301 14.9176 5.46549 14.5119L8.92216 12.9407ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
      fill="currentColor"
    />
  </svg>
);
