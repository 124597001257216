import type { ComponentPropsWithoutRef } from 'react';

const Contract = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 4.52898V17.501C16 18.8817 14.8807 20.001 13.5 20.001H2.5C1.11929 20.001 0 18.8817 0 17.501V2.501C0 1.12029 1.11929 0.00100238 2.5 0.00100238H11.472C11.6048 -0.00741709 11.7429 0.036842 11.8536 0.147449L15.8536 4.14745C15.9642 4.25806 16.0084 4.39623 16 4.52898ZM11 1.001H2.5C1.67157 1.001 1 1.67258 1 2.501V17.501C1 18.3294 1.67157 19.001 2.5 19.001H13.5C14.3284 19.001 15 18.3294 15 17.501V5.001H11.5C11.2239 5.001 11 4.77714 11 4.501V1.001ZM12 1.70811V4.001H14.2929L12 1.70811ZM4.5 8C4.22386 8 4 7.77614 4 7.5C4 7.22386 4.22386 7 4.5 7H11.5C11.7761 7 12 7.22386 12 7.5C12 7.77614 11.7761 8 11.5 8H4.5ZM4.5 11C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5ZM4.5 14C4.22386 14 4 13.7761 4 13.5C4 13.2239 4.22386 13 4.5 13H9.5C9.77614 13 10 13.2239 10 13.5C10 13.7761 9.77614 14 9.5 14H4.5Z"
      fill="currentColor"
    />
  </svg>
);

export default Contract;
