import twMerge from '../../utils/twMerge';
import type { ReactNode } from 'react';

type NoResultProps = {
  noResultText: string;
  icon: ReactNode;
  className?: string;
};

const NoResult = ({ noResultText, icon, className }: NoResultProps) => {
  return (
    <div
      className={twMerge(
        'flex flex-col items-center justify-center py-6',
        className
      )}
    >
      <div className="rounded-sm bg-mischka p-2">{icon}</div>
      <p className="mt-2 text-center text-xs text-shuttlegray">
        {noResultText}
      </p>
    </div>
  );
};

export default NoResult;
