import { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useModal } from '../../context/ModalContext';

import './modal.css';

const body = document.querySelector('body');
let boundAnimationEnded;

const Modal = () => {
  const { modalContent, modal, handleModal } = useModal();
  const modalRef = useRef(null);

  const handleClick = (event) => {
    if (
      !modalRef?.current?.contains(event.target) &&
      event.target.getAttribute('data-modalid') === 'modal-blur'
    ) {
      handleModal();
    } else {
      return;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
    // eslint-disable-next-line
  });

  if (modal) {
    return ReactDOM.createPortal(
      <section
        className={`${
          modal ? `z-[60]` : '-z-1'
        } modal-blur top-0 right-0 absolute h-screen w-screen bg-uberblue/30 cursor-pointer`}
        data-modalid="modal-blur"
        id="modal-container"
      >
        <div
          className={`modal font-lato ${
            modal ? 'enter' : ''
          } overflow-y-scroll absolute top-0 h-screen w-screen md:w-2/3 lg:w-1/2 xl:w-5/12 bg-white`}
          style={{ cursor: 'auto' }}
          ref={modalRef}
        >
          {modalContent}
        </div>
      </section>,
      document.querySelector('#modal-root')
    );
  } else return null;
};

const registerComponent = (component, scrollable = false) => {
  return {
    payload: {
      modalComponent: component,
      isModalOpen: true,
      scrollable,
    },
    type: 'REGISTER_COMPONENT',
  };
};

const unregisterComponent = () => ({
  type: 'UNREGISTER_COMPONENT',
});

const onAnimationEnd = (cb, dispatcher) => {
  const modal = document.querySelector('.modal');

  body.classList.remove('disable-scroll');
  modal.removeEventListener('animationend', boundAnimationEnded);
  setTimeout(() => {
    if (cb) cb();
    dispatcher(unregisterComponent());
  }, 50);
};

export const open = (component, dispatch, scrollable = false) => {
  body.classList.add('disable-scroll');
  return dispatch(registerComponent(component, scrollable));
};

export const close = (cb, dispatcher) => {
  const modal = document.querySelector('.modal');

  boundAnimationEnded = onAnimationEnd.bind(null, cb, dispatcher);
  modal.addEventListener('animationend', boundAnimationEnded);
  modal.classList.add('exit');
};

export default Modal;
