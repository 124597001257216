const Error = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.884 0.532004C10.538 -0.121996 9.46196 -0.121996 9.11596 0.532004L0.115959 17.532C0.0349422 17.6844 -0.00522229 17.8551 -0.000621372 18.0276C0.00397955 18.2001 0.0531889 18.3685 0.142212 18.5164C0.231235 18.6642 0.357036 18.7864 0.507356 18.8712C0.657676 18.956 0.827389 19.0003 0.999959 19H19C19.1724 19.0004 19.342 18.956 19.4922 18.8713C19.6424 18.7866 19.7681 18.6644 19.8571 18.5167C19.946 18.3689 19.9951 18.2006 19.9997 18.0282C20.0042 17.8559 19.964 17.6852 19.883 17.533L10.884 0.532004ZM11 16H8.99996V14H11V16ZM8.99996 12V7H11L11.001 12H8.99996Z"
        fill="#D43A42"
      />
    </svg>
  );
};

export default Error;
