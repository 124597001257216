import { useEffect, useState } from 'react';
import Dropdown from './Ui/Dropdown';

import { ArrowDown } from '@kudyfinancials/livre';
import formatMoney from '@/utils/formatMoney';

export const AnalyticsCard = ({ options = [] }) => {
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setSelected(options[0] || {});
  }, [options]);

  return (
    <div className={`bg-mischka md:w-[266px] p-4 space-y-16 flex-shrink-0`}>
      <div className="flex items-center justify-between">
        <Dropdown
          options={options}
          icon={ArrowDown}
          onChange={setSelected}
          selected={
            <p className="text-ebonyclay font-bold">
              {selected.title || selected.label || '-'}
            </p>
          }
        />
      </div>
      <p className="text-ebonyclay font-bold text-2xl">
        {selected.currency
          ? formatMoney(
              selected.total || selected.value || 0,
              selected.currency,
              {
                shouldRoundDown: true,
                roundDownAmount: 9_999_999,
                decimalPoints: 2,
              }
            )
          : selected.total || selected.value || 0}
      </p>
    </div>
  );
};
