import { useLocation } from 'react-router-dom';
import useEDDDocuments from '../../hooks/useEDDDocuments';
import { useAppQuery } from '../../hooks/useAppQuery';
import { format, parseISO, isValid } from 'date-fns';
import { Button } from '../Button';
import { Fragment } from 'react';
import { KudyLogoText, Loader } from '@kudyfinancials/livre';

const EDDForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userId = searchParams.get('userId');
  const eddId = searchParams.get('eddId');
  const { data, isLoading } = useEDDDocuments(userId);

  const { data: account } = useAppQuery(`user-account_${userId}`, {
    url: `v1/accounts/${userId}`,
  });

  const { data: docs } = useAppQuery(`user-documents_${userId}`, {
    url: `v1/users/${userId}/documents`,
  });

  const accountType = account?.data.type;

  const checkIdentificationDocument = () => {
    const documentTypeMap = {
      intl_passport: 'International Passport',
      drivers_license: "Driver's Licence",
      nin: 'National Identification Number (NIN)',
      cac: 'Corporate Affairs Commission Document',
    };

    const identificationDocuments =
      accountType === 'personal'
        ? ['intl_passport', 'drivers_license', 'nin']
        : ['cac'];

    const availableDocument = docs?.data
      .filter((doc) => identificationDocuments.includes(doc.type))
      .map((doc) => documentTypeMap[doc.type]);

    return availableDocument.length === 0 ? '-' : availableDocument;
  };

  const entry = data?.entries.find((entry) => entry.id === eddId);
  const valueMap = {
    true: 'Yes',
    false: 'No',
    main: 'M-Main',
    joint: 'J-Joint',
  };

  const parseDateValue = (value) => {
    if (typeof value === 'string') {
      const date = parseISO(value);
      if (isValid(date)) {
        return format(date, 'dd/MM/yyyy');
      } else {
        return 'Invalid date';
      }
    } else {
      return value;
    }
  };

  if (isLoading) return <Loader />;

  const fundSourceMap = {
    business_profit: 'Business Profit',
    savings: 'Savings',
    salary: 'Salary',
    gift_inheritance: 'Gift Inheritance',
    sale_of_real_estate: 'Sale of Real Estate',
    investment_earnings: 'Investment Earnings',
    others: 'Others',
  };

  const renderDocumentLinks = (documents = []) =>
    documents.length === 0
      ? '-'
      : documents.map((url, i) => (
          <Fragment key={`document-${i}`}>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-jungleGreen cursor-pointer hover:underline"
            >
              Document {i + 1}
            </a>
            {i < documents.length - 1 && ', '}
          </Fragment>
        ));

  const renderSection = (title, fields, map = {}) => (
    <section className="border-uberblue border-b pb-10">
      <h3 className="text-18 my-4 font-bold">{title}</h3>
      {fields.map(({ label, key, format }) => {
        const value = ['fullname'].includes(key)
          ? account?.data?.[key]
          : entry?.[key];

        let displayValue;

        if (Array.isArray(value)) {
          displayValue = value.map((v) => map[v] || v).join(', ');
        } else if (value in valueMap) {
          displayValue = valueMap[value];
        } else if (format) {
          displayValue = format(value);
        } else {
          displayValue = value;
        }

        return (
          <div className="flex my-4 gap-4 lg:gap-20 print:gap-20" key={key}>
            <p className="flex-1 lg:text-14 text-12 print:text-14 text-blueWood flex-wrap">
              {label}
            </p>
            <p className="flex-1 lg:text-14 text-12 print:text-14 text-ebonyclay flex-wrap">
              {displayValue !== null && displayValue !== undefined
                ? displayValue
                : '-'}
            </p>
          </div>
        );
      })}
    </section>
  );

  return (
    <>
      {entry && (
        <div
          className="w-11/12 mx-auto mt-[64px] print:mt-10 md:mt-10 font-lato"
          key={eddId}
        >
          <div className="flex flex-col">
            <div className="flex justify-between items-center">
              <div className="w-1/4 print:w-fit">
                <KudyLogoText ltd />
              </div>
              <div className="print:w-full mx-auto md:w-1/2 text-center">
                <h2 className="text-24 print:text-20 font-bold">
                  Enhanced Due Diligence Questionnaire
                </h2>
              </div>
              <div className="absolute md:relative print:hidden right-4 top-3 md:w-1/4 text-right">
                <Button className="print:hidden" onClick={() => window.print()}>
                  Download PDF
                </Button>
              </div>
            </div>
          </div>
          <p className="text-14 lg:text-base w-full lg:w-7/12 print:w-9/12 mx-auto print:text-14 text-center mt-3 mb-10 ">
            As part of Kudy Financials Limited’s (the “Company”) Anti Money
            Laundering (“AML”) control, these questions have been answered by{' '}
            {account?.data?.fullname} to meet up with compliance regulations
          </p>

          {renderSection(
            `${accountType === 'personal' ? 'Personal Information' : 'Account Information'}`,
            [
              ...(accountType === 'personal'
                ? [{ label: 'Full Name', key: 'fullname' }]
                : [{ label: 'Account Name', key: 'fullname' }]),

              {
                label: 'Type of Identification',
                format: () => {
                  return docs && checkIdentificationDocument();
                },
              },
              { label: 'Type of Account', key: 'type_of_account' },
            ]
          )}

          {renderSection('Occupation / Nature of Business', [
            {
              label:
                'Are you involved in the business of/employed by the following activities/employers that are: Money changers, Remittance Agents, Pawnbrokers, Internet-based Value Facility Holders, Bureau de Change?',
              key: 'business_of_x',
            },
            ...(entry?.business_of_x === true
              ? [
                  { label: 'Type of Business', key: 'type_of_business' },
                  { label: 'Job Title', key: 'job_title' },
                ]
              : []),
            {
              label:
                'In your occupation/business, do you deal with any individual or entity from the countries below: Belarus, Cuba, Central African Republic, Iran, Libya, North Korea, Russia, Somalia, Syria, Sudan and Zimbabwe?',
              format: () => {
                return `${valueMap[entry?.business_with_flagged_countries]}. ${entry?.flagged_countries || ''}`;
              },
            },

            ...(entry?.business_with_flagged_countries === true
              ? [
                  {
                    label: 'Explain the nature of these dealings',
                    key: 'nature_of_dealings_with_flagged_countries',
                  },
                ]
              : []),
          ])}

          {renderSection('Politically Exposed Persons', [
            {
              label:
                'Are you a PEP/Close associate of PEP/Family member of PEP?',
              key: 'is_pep_associate',
            },
            ...(entry?.is_pep_associate === true
              ? [
                  {
                    label: 'Are you the politically exposed persons?',
                    key: 'is_pep',
                  },
                  ...(entry?.is_pep === true
                    ? [
                        {
                          label:
                            'What is the name of your organisation/employer?',
                          key: 'is_pep_name_of_employer',
                        },
                        {
                          label: 'What is your occupation/position?',
                          key: 'is_pep_occupation',
                        },

                        {
                          label: 'What is your period of service?',
                          format: () => {
                            const from = parseDateValue(
                              entry?.is_pep_period_of_service_from
                            );
                            const to = parseDateValue(
                              entry?.is_pep_period_of_service_to
                            );
                            const toIsCurrent =
                              entry?.is_pep_period_of_service_to_current;
                            return from && to
                              ? `${from} - ${to}`
                              : from && toIsCurrent
                                ? `${from} - I currently work here`
                                : '-';
                          },
                        },
                        {
                          label:
                            'Is there any expected receipt of large sums of monies from government bodies or state owned entities?',

                          format: () => {
                            return `${valueMap[entry?.receipt_of_sums_of_monies]}. ${entry?.receipt_of_sums_of_monies_explain || ''}`;
                          },
                        },
                        {
                          label:
                            'Will there be any usage of government account(s) as source of funds in transactions?',
                          key: 'usage_of_govt_account_as_source',
                        },
                      ]
                    : [
                        {
                          label:
                            'What is the name of the immediate family member/close associate who is the PEP?',
                          key: 'is_pep_name_of_individual',
                        },
                        {
                          label:
                            'What is the name of his/her employer/organisation?',
                          key: 'is_pep_name_of_employer',
                        },
                        {
                          label: 'What is his/her occupation/position?',
                          key: 'is_pep_occupation',
                        },

                        {
                          label:
                            'Is there any expected receipt of large sums of monies from government bodies or state owned entities?',
                          format: () => {
                            return `${valueMap[entry?.receipt_of_sums_of_monies]}. ${entry?.receipt_of_sums_of_monies_explain || ''}`;
                          },
                        },
                        {
                          label:
                            'Will there be any usage of government account(s) as source of funds in transactions?',
                          key: 'usage_of_govt_account_as_source',
                        },
                      ]),
                ]
              : []),
          ])}

          {renderSection(
            'Source of Fund',
            [
              {
                label: 'What is your source of fund?',
                key: 'fund_source',
              },
            ],
            fundSourceMap
          )}

          {entry?.fund_source.includes('business_profit') &&
            renderSection('Business Profit', [
              {
                label:
                  'What is the name of the company and the country of incorporation (including registration number)?',
                key: 'fund_source_business_profit_business_name',
              },
              {
                label:
                  'Please describe the nature of business and its operation',
                key: 'fund_source_business_profit_business_nature',
              },
              {
                label:
                  'What capacity do you hold in the business that enable you to receive profits?',
                key: 'fund_source_business_profit_profit_capacity',
              },
            ])}

          {entry?.fund_source.includes('gift_inheritance') &&
            renderSection('Gift Inheritance', [
              {
                label:
                  'Please provide the name of the person whom you inherited the wealth from',
                key: 'fund_source_gift_inheritance_inherited_from_name',
              },
              {
                label: 'What is the relationship between the two of you?',
                key: 'fund_source_gift_inheritance_relationship',
              },
              {
                label: 'What is the reason for such gift/inheritance?',
                key: 'fund_source_gift_inheritance_reason',
              },
              {
                label:
                  'What type of wealth did you receive (e.g. Land, Securities, Company, Trust etc)?',
                key: 'fund_source_gift_inheritance_wealth_type',
              },
            ])}

          {entry?.fund_source.includes('savings') &&
            renderSection('Savings', [
              {
                label:
                  'Please provide the name of your previous employer/business which contributed to the greatest share of your savings',
                key: 'fund_source_savings_previous_employer',
              },
              {
                label:
                  'In which industry does the above-mentioned company belong? What was your position held then?',
                key: 'fund_source_savings_employer_industry',
              },
              {
                label: 'How many months were you with the above named company?',
                key: 'fund_source_savings_employer_months_employed_with',
              },
              {
                label: 'How many years have you been in the industry?',
                key: 'fund_source_savings_years_in_industry',
              },
            ])}

          {entry?.fund_source.includes('salary') &&
            renderSection('Salary', [
              {
                label:
                  "What is the name of your employer? In what industry is your employer's business?",
                key: 'fund_source_salary_employer_name_and_industry',
              },
              {
                label:
                  'What is your profession (including area of speciality)?',
                key: 'fund_source_salary_profession',
              },
              {
                label: 'How many months have you been in this line of work?',
                key: 'fund_source_salary_employer_months_of_profession',
              },
              {
                label: 'How many months have you been with your employer?',
                key: 'fund_source_salary_years_in_industry',
              },
            ])}

          {entry?.fund_source.includes('sale_of_real_estate') &&
            renderSection('Sale of Real Estate', [
              {
                label: 'Who does the real estate legally belong to?',
                key: 'fund_source_sale_of_real_estate_legal_owner',
              },
              {
                label:
                  'What type of real estate was sold and when was it sold?',
                key: 'fund_source_sale_of_real_estate_type_and_when',
              },
            ])}

          {entry?.fund_source.includes('investment_earnings') &&
            renderSection('Investment Earnings', [
              {
                label: 'How many months has this investment been held for?',
                key: 'fund_source_investment_earnings_investment_period',
              },
              {
                label:
                  'Are these investment earnings resulting from the regular income (e.g. fixed coupon payment/fixed dividends)?',
                key: 'fund_source_investment_earnings_result_from_income',
              },
            ])}

          {entry?.fund_source.includes('others') &&
            renderSection('Other', [
              {
                label:
                  'Please describe the nature of business and its operation',
                key: 'fund_source_others',
              },
            ])}

          {renderSection('Documents', [
            {
              label: 'Please upload any other support documents',
              format: () =>
                renderDocumentLinks(
                  entry?.fund_source_additional_information_support_docs
                ),
            },
          ])}

          {renderSection(
            'Source of Wealth',
            [
              {
                label: 'Please indicate your source of wealth',
                key: 'wealth_source',
              },
            ],
            fundSourceMap
          )}

          {entry?.wealth_source.includes('business_profit') &&
            renderSection('Business Profit', [
              {
                label:
                  'What is the name of the company and the country of incorporation (including registration number)?',
                key: 'wealth_source_business_profit_business_name',
              },
              {
                label:
                  'Please describe the nature of business and its operation',
                key: 'wealth_source_business_profit_business_nature',
              },
              {
                label:
                  'What capacity do you hold in the business that enable you to receive profits?',
                key: 'wealth_source_business_profit_profit_capacity',
              },
            ])}

          {entry?.wealth_source.includes('gift_inheritance') &&
            renderSection('Gift Inheritance', [
              {
                label:
                  'Please provide the name of the person whom you inherited the wealth from',
                key: 'wealth_source_gift_inheritance_inherited_from_name',
              },
              {
                label: 'What is the relationship between the two of you?',
                key: 'wealth_source_gift_inheritance_relationship',
              },
              {
                label: 'What is the reason for such gift/inheritance?',
                key: 'wealth_source_gift_inheritance_reason',
              },
              {
                label:
                  'What type of wealth did you receive (e.g. Land, Securities, Company, Trust etc)?',
                key: 'wealth_source_gift_inheritance_wealth_type',
              },
            ])}

          {entry?.wealth_source.includes('savings') &&
            renderSection('Savings', [
              {
                label:
                  'Please provide the name of your previous employer/business which contributed to the greatest share of your savings',
                key: 'wealth_source_savings_previous_employer',
              },
              {
                label:
                  'In which industry does the above-mentioned company belong? What was your position held then?',
                key: 'wealth_source_savings_employer_industry',
              },
              {
                label: 'How many months were you with the above named company?',
                key: 'wealth_source_savings_employer_months_employed_with',
              },
              {
                label: 'How many years have you been in the industry?',
                key: 'wealth_source_savings_years_in_industry',
              },
            ])}

          {entry?.wealth_source.includes('salary') &&
            renderSection('Salary', [
              {
                label:
                  "What is the name of your employer? In what industry is your employer's business?",
                key: 'wealth_source_salary_employer_name_and_industry',
              },
              {
                label:
                  'What is your profession (including area of speciality)?',
                key: 'wealth_source_salary_profession',
              },
              {
                label: 'How many months have you been in this line of work?',
                key: 'wealth_source_salary_employer_months_of_profession',
              },
              {
                label: 'How many months have you been with your employer?',
                key: 'wealth_source_salary_years_in_industry',
              },
            ])}

          {entry?.wealth_source.includes('sale_of_real_estate') &&
            renderSection('Sale of Real Estate', [
              {
                label: 'Who does the real estate legally belong to?',
                key: 'wealth_source_sale_of_real_estate_legal_owner',
              },
              {
                label:
                  'What type of real estate was sold and when was it sold?',
                key: 'wealth_source_sale_of_real_estate_type_and_when',
              },
            ])}

          {entry?.wealth_source.includes('investment_earnings') &&
            renderSection('Investment Earnings', [
              {
                label: 'How many months has this investment been held for?',
                key: 'wealth_source_investment_earnings_investment_period',
              },
              {
                label:
                  'Are these investment earnings resulting from the regular income (e.g. fixed coupon payment/fixed dividends)?',
                key: 'wealth_source_investment_earnings_result_from_income',
              },
            ])}

          {entry?.wealth_source.includes('others') &&
            renderSection('Other', [
              {
                label:
                  'Please describe the nature of business and its operation',
                key: 'wealth_source_others',
              },
            ])}

          {renderSection('Documents', [
            {
              label: 'Please upload any other support documents',
              format: () =>
                renderDocumentLinks(
                  entry?.wealth_source_additional_information_support_docs
                ),
            },
          ])}

          {renderSection('Additonal Information', [
            {
              label:
                'Please indicate your expected amount($) for each transaction',
              key: 'additional_information_expected_amount_per_transaction',
            },
          ])}
          {renderSection('Declaration', [
            {
              label:
                'We hereby declare that the answers given above are true and complete to the best of my/our knowledge and belief and that I/We have not made any false or fraudulent statement, any suppression and concealment of facts. If any information is found to be false/misleading, or if the need to obtain further information is not met, the company may reject any of my/our application and/or instructions including but not limited to, any transactional-related activities. I/We consent to the Company’s collection and usage of information in this form and that the information is used for the purpose of assessing my/our application.',
              format: () => {
                return `${account?.data.fullname}`;
              },
            },
          ])}
        </div>
      )}
    </>
  );
};

export default EDDForm;
