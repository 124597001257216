import { useState, useEffect, useRef } from 'react';
import ModalBase from '../../modal/ModalBase';
import NewCheckbox from '../../NewCheckbox';
import Delete from '../../../utils/assets/Delete';
import { Button, ButtonWithIcon } from '../../Button';
import Clear from '../../../utils/assets/Clear';
import { useFormik } from 'formik';
import { useAppMutation } from '../../../hooks/useAppQuery';
import { Toast } from '../../../helpers/toastify.helpers';
import { useQueryClient } from '@tanstack/react-query';
import { array, boolean, object, string } from 'yup';
import ErrorText from '../../ErrorText';
import { useModal } from '../../../context/ModalContext';
import { ArrowDown } from '@kudyfinancials/livre';

export const RejectDocumentModal = ({ userId, id, name, documentUrl }) => {
  const [showOption, setShowOptions] = useState(false);
  const [newReason, setNewReason] = useState('');
  const queryClient = useQueryClient();
  const { handleModal } = useModal();

  const reasons = localStorage.getItem('doc_rejection_reasons')
    ? JSON.parse(localStorage.getItem('doc_rejection_reasons'))
    : [];

  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleReasonAddition = () => {
    if (newReason.trim() !== '') {
      localStorage.setItem(
        'doc_rejection_reasons',
        JSON.stringify([...reasons, newReason])
      );
      setNewReason('');
    }
  };

  const handleReasonSelection = (reason) => {
    const prevSelectedReasons = formik.values.selectedReasons;
    const updatedSelectedReasons = prevSelectedReasons.includes(reason)
      ? prevSelectedReasons.filter((r) => r !== reason)
      : [...prevSelectedReasons, reason];
    formik.setFieldValue('selectedReasons', updatedSelectedReasons);
  };

  const handleReasonDeletion = (reasonToDelete) => {
    localStorage.setItem(
      'doc_rejection_reasons',
      JSON.stringify(reasons.filter((reason) => reason !== reasonToDelete))
    );
    const prevSelectedReasons = formik.values.selectedReasons;
    const updatedSelectedReasons = prevSelectedReasons.filter(
      (selectedReason) => selectedReason !== reasonToDelete
    );
    formik.setFieldValue('selectedReasons', updatedSelectedReasons);
  };

  const { mutate: rejectDocument, isPending: isLoading } = useAppMutation(
    {
      url: `/v1/documents/${id}`,
      method: 'DELETE',
    },
    {
      onSettled: async (_, error) => {
        if (error) {
          const { message } = error.response.data;
          Toast({
            message,
            type: 'error',
          });
          handleModal();
          return;
        }

        Toast({
          message: `Document Deleted`,
          type: 'success',
        });
        await queryClient.invalidateQueries({
          queryKey: [`user-documents_${userId}`],
          refetchType: 'all',
        });

        await queryClient.invalidateQueries({
          queryKey: [`pending-tasks`],
          refetchType: 'all',
        });
        handleModal();
      },
    }
  );

  const schema = object().shape({
    selectedReasons: array().of(string()).min(1, 'Please select a reason'),
    confirmation: boolean().oneOf([true], 'You have to confirm this action'),
  });

  const formik = useFormik({
    initialValues: { selectedReasons: [], confirmation: false },
    validationSchema: schema,
    enableReinitialize: true,

    onSubmit: ({ selectedReasons }) => {
      rejectDocument({
        reasons: selectedReasons,
      });
    },
  });

  return (
    <ModalBase title="Reject Document">
      <form onSubmit={formik.handleSubmit}>
        <h2>You are about to reject this {name}</h2>
        {documentUrl && (
          <a
            className="cursor-pointer border-0.5 border-loblolly block text-center text-jungleGreen p-2 rounded-sm my-4"
            rel="noreferrer"
            href={documentUrl}
            target="_blank"
          >
            View Document
          </a>
        )}
        <section className="relative mt-8 mb-4">
          <h3>Reason for rejection</h3>
          <div
            ref={inputRef}
            className="cursor-pointer border p-3 w-full out active:outline-none focus:border-jungleGreen focus:outline-none flex items-center justify-between mt-2 rounded"
            onClick={() => !showOption && setShowOptions(true)}
          >
            <div className="flex flex-wrap gap-2 w-full">
              {formik.values.selectedReasons.length > 0 ? (
                formik.values.selectedReasons.map((reason, index) => (
                  <div
                    key={`${reason}-${index}`}
                    className="bg-uberblue py-0.5 flex justify-between gap-2 items-center px-2 text-white rounded-full"
                  >
                    <span className="text-14"> {reason}</span>
                    <ButtonWithIcon
                      onClick={() => handleReasonSelection(reason)}
                    >
                      <Clear color="#FFFFFF" />
                    </ButtonWithIcon>
                  </div>
                ))
              ) : (
                <p className="text-loblolly">Select</p>
              )}
            </div>
            <ButtonWithIcon onClick={() => setShowOptions(!showOption)}>
              <ArrowDown />
            </ButtonWithIcon>
          </div>
          {showOption && (
            <div
              className="absolute w-full z-2 rounded border py-3 px-6 bg-white h-max mt-2.5 shadow-xl"
              ref={dropdownRef}
            >
              <div className="border flex items-center justify-between px-2 p-1 my-2 rounded">
                <input
                  className="focus:outline-none p-1 w-full placeholder:text "
                  placeholder="Enter a reason"
                  onChange={(e) => setNewReason(e.target.value)}
                  value={newReason}
                />
                <button
                  className="border px-2 py-1 text-14 rounded"
                  onClick={handleReasonAddition}
                >
                  Add
                </button>
              </div>

              {reasons.map((reason, index) => (
                <div
                  key={`${reason}-${index}`}
                  className="flex items-center justify-between my-1"
                >
                  <div className="p-2">
                    <NewCheckbox
                      label={reason}
                      onChange={() => handleReasonSelection(reason)}
                      checked={formik.values.selectedReasons.includes(reason)}
                    />
                  </div>

                  <ButtonWithIcon onClick={() => handleReasonDeletion(reason)}>
                    <Delete />
                  </ButtonWithIcon>
                </div>
              ))}
            </div>
          )}
          <ErrorText
            errored={
              formik.touched.selectedReasons && formik.errors.selectedReasons
            }
            errorMessage={formik.errors.selectedReasons}
          />
        </section>
        <div>
          <NewCheckbox
            label="Confirm that you want this document rejected."
            checked={formik.values.confirmation}
            onChange={(value) => formik.setFieldValue('confirmation', value)}
          />
          <ErrorText
            errored={formik.touched.confirmation && formik.errors.confirmation}
            errorMessage={formik.errors.confirmation}
          />
        </div>
        <div className="flex justify-center">
          <Button
            className="mt-10"
            type="submit"
            loading={isLoading}
            disabled={!formik.isValid}
          >
            Reject Document
          </Button>
        </div>
      </form>
    </ModalBase>
  );
};
