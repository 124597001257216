import { styled, css } from 'styled-components';
import twMerge from '../utils/twMerge';

/**
 * @param {Object} props A react component props
 * @returns LinedButton
 */
const UnderlinedBtn = styled.button.attrs({
  className: 'border-b relative focus:outline-none',
})`
  border-color: ${({ style }) => (style?.color ? style?.color : `black`)};

  &:after {
    padding-left: 5px;
    content: '+';
    font-size: 28px;
    height: 36px;
    position: absolute;
    top: -11px;

    border-bottom: 1px solid
      ${({ style }) => (style?.color ? style?.color : `black`)};
  }

  ${(props) =>
    props.table &&
    css`
      @media (max-width: 720px) {
        &:after {
          font-size: 23px;
          height: 31px;
        }
      }
    `}
  ${(props) =>
    props.margined &&
    css`
      margin-right: 40px;
    `}

  ${({ block }) =>
    block &&
    css`
      display: block;
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

/**
 * UnderlinedButton component
 * @param {Object} props A react component props
 * @returns jsx
 */
export const UnderlinedButton = ({
  label,
  type = 'button',
  loading = false,
  disabled = false,
  onClick,
  styleClasses = '',
  margined,
  block,
  children,
  table,
  ...rest
}) => {
  return (
    <UnderlinedBtn
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={styleClasses}
      margined={margined}
      block={block}
      table={table}
      {...rest}
    >
      {loading ? 'Loading...' : label || children}
    </UnderlinedBtn>
  );
};

/**
 * @returns Button
 */
const Btn = styled.button.attrs({
  className:
    'block w-full py-3 mt-12 text-white uppercase border-solid border border-deyork focus:outline-none bg-transparent font-butler',
})``;

/**
 * @returns Btn Component
 */
export const OutlinedButton = ({
  label,
  loading = false,
  type = 'submit',
  styleClasses = '',
  disabled,
  onClick,
  children,
}) => {
  return (
    <>
      <Btn
        type={type}
        disabled={disabled || loading}
        className={styleClasses}
        onClick={onClick}
      >
        {loading ? 'Loading...' : label || children}
      </Btn>
    </>
  );
};

export const NewButton = ({ handleClick, label, styles, disabled, type }) => {
  const buttonStyles = `p-3 text-center outline-none active:outline-none focus:outline-none rounded-sm ${
    disabled ? 'bg-gallery text-uberblue cursor-not-allowed text-white' : styles
  }`;
  return (
    <div className="text-center">
      <button
        type={type}
        onClick={handleClick}
        className={`${buttonStyles} ${disabled && 'bg-gallery text-uberblue cursor-not-allowed'}`}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
};
export const ButtonWithIcon = ({
  children,
  className,
  disabled,
  loading,
  ...props
}) => {
  return (
    <button
      type="button"
      className={twMerge(
        'flex space-x-2 justify-center items-center rounded-sm focus:outline-none',
        className
      )}
      disabled={disabled || loading}
      {...props}
    >
      {children}
    </button>
  );
};

export const Button = ({
  loading,
  loadingIndicator = 'Loading...',
  className,
  bgColor = 'ebonyclay',
  disabled,
  children,
  icon,
  variant = 'solid',
  ...props
}) => {
  const baseClasses =
    'bg-transparent text-ebonyclay py-3 px-4 text-sm focus:outline-none rounded-sm disabled:bg-gallery disabled:text-uberblue transition-all duration-200';
  const colorVariants = {
    ebonyclay: 'bg-ebonyclay hover:bg-ebonyclayHover active:bg-ebonyclay',
    valencia: 'bg-valencia hover:bg-valenciaHover active:bg-valencia',
  };
  let variantClasses;
  switch (variant) {
    case 'solid':
      variantClasses = `text-white ${colorVariants[bgColor]}`;
      break;
    case 'outline':
      variantClasses =
        'border-0.5 border-loblolly hover:bg-mischka hover:enabled:border-ebonyclay';
      break;
    case 'ghost':
      variantClasses = 'hover:bg-gallery';
      break;
    default:
      break;
  }
  return (
    <button
      type="button"
      disabled={disabled || loading}
      className={twMerge(baseClasses, variantClasses, className)}
      {...props}
    >
      {loading ? loadingIndicator : children}
      {icon ? icon : null}
    </button>
  );
};
