import stringToAvatar from '@/utils/stringToAvatar';
import type { User } from '@kudyfinancials/livre';
import type { MouseEventHandler } from 'react';

type AvatarProps = {
  user: User;
  onClick: MouseEventHandler<HTMLImageElement>;
};

const Avatar = ({ user, onClick }: AvatarProps) => {
  return (
    <img
      onClick={onClick}
      className="size-10 rounded-full inline-block"
      src={stringToAvatar(user)}
      alt="Profile Avatar"
    />
  );
};

export default Avatar;
