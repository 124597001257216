import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import RestrictedRoutes from '../components/RestrictedRoutes';
import PrivateRoutes from '../components/PrivateRoutes';
import { ModalProvider } from '../context/ModalContext';
import CustomToast from '../components/Toast';
import EDDForm from '../components/EDD/EDDForm';
import { useAppRoutes } from '.';

const Login = lazy(() => import('../pages/Login'));
const AccountStatement = lazy(() => import('../components/AccountStatement'));
const AppRoutes = () => {
  const { dashboardRoutes } = useAppRoutes();

  return (
    <ModalProvider>
      <CustomToast />
      <Routes>
        <Route path="/" element={<RestrictedRoutes />} />
        <Route path="/login" element={<Login />} />
        <Route element={<PrivateRoutes />}>
          {dashboardRoutes.map(({ path, Component }, key) => (
            <Route path={path} key={`${path}-${key}`} element={<Component />} />
          ))}
        </Route>
        <Route path="/statement/:userId" element={<AccountStatement />} />
        <Route path="/edd" element={<EDDForm />} />
      </Routes>
    </ModalProvider>
  );
};
export default AppRoutes;
