import { styled } from 'styled-components';
import { ToastContainer } from 'react-toastify';

const CustomToast = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
  closeButton: false,
})`
  min-width: 280px;
  width: fit-content;

  .Toastify__toast {
    min-height: 48px;
    padding: 0 18px;
    @media (min-width: 280px) and (max-width: 480px) {
      width: 100%;
      margin-top: 4px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .Toastify__toast--error {
    border: 1px solid #d43a42;
    border-radius: 4px;
    color: #d43a42;
    background-color: #ffeeef;
  }

  .Toastify__toast--success {
    border: 1px solid #26c07f;
    border-radius: 4px;
    color: #26c07f;
    background-color: #f7fefb;
  }
`;

export default CustomToast;
