import { createContext, useContext, useReducer } from 'react';
import { kreditReducer } from './reducers/kredit';

export const initialState = {
  selectedInfo: 'kreditProfiles',
};

const KreditContext = createContext(initialState);

const KreditProvider = ({ children }) => {
  const [state, dispatch] = useReducer(kreditReducer, initialState);

  const selectKreditProfiles = () => {
    dispatch({ type: 'SELECT_KREDIT_PROFILES' });
  };

  const selectKreditTransactions = () => {
    dispatch({ type: 'SELECT_KREDIT_TRANSACTIONS' });
  };

  return (
    <KreditContext.Provider
      value={{
        selectedInfo: state.selectedInfo,
        selectKreditProfiles,
        selectKreditTransactions,
      }}
    >
      {children}
    </KreditContext.Provider>
  );
};

const useKredit = () => useContext(KreditContext);

export { KreditProvider, useKredit };
