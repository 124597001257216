import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './context/Auth';
import Routes from './routes/Routes';
import { KreditProvider } from './context/Kredit';
import { PromptProvider } from './context/PromptContext';
import { Buffer } from 'buffer';
import { FullPageLoader } from '@kudyfinancials/livre';

declare global {
  interface Window {
    Buffer: typeof Buffer;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3600000,
      retry: false,
    },
  },
});

window.Buffer = window.Buffer || Buffer;

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<FullPageLoader />}>
        <AuthProvider>
          <KreditProvider>
            <PromptProvider>
              <main className="font-lato">
                <Routes />
              </main>
            </PromptProvider>
          </KreditProvider>
        </AuthProvider>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
