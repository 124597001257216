import { RejectDocumentModal } from '@/components/KYC/Modals/RejectDocumentModal';
import Dropdown from '@/components/Ui/Dropdown';
import { useModal } from '@/context/ModalContext';
import { Toast } from '@/helpers/toastify.helpers';
import { useAppMutation } from '@/hooks/useAppQuery';
import Kebab from '@/utils/assets/Kebab';
import { formatDateTime } from '@/utils/formatValues';
import { Badge } from '@kudyfinancials/livre';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import RejectEDDDocument from '@/components/EDD/RejectEDDDocument';
import { TableItem } from '@/components/Table/TableItem';

const PendingTasks = ({
  id,
  userId,
  dateUploaded,
  userProfile,
  email,
  activity,
  documentUrl,
  status,
  documentText,
}) => {
  const navigate = useNavigate();
  const { handleModal } = useModal();
  const queryClient = useQueryClient();

  const isEDDForm = activity === 'EDD Form';

  const kebabActions = [
    {
      label: 'View Profile',
      value: 'viewProfile',
    },
    {
      label: 'Approve Document',
      value: 'approve',
      status: status === 0 || 'pending',
    },
    {
      label: 'Reject Document',
      value: 'reject',
      status: !!status,
    },
  ];

  const { mutate: approveDocument } = useAppMutation(
    {
      url: isEDDForm ? `/v1/users/${userId}/edd` : `/v1/documents/${id}`,
      method: 'PATCH',
    },
    {
      onSettled: async (_, error) => {
        if (error) {
          const { message } = error.response.data;
          Toast({
            message,
            type: 'error',
          });
          return;
        }
        Toast({
          message: `Document Approved`,
          type: 'success',
        });
        await queryClient.invalidateQueries({
          queryKey: [`pending-tasks`],
          refetchType: 'all',
        });

        if (isEDDForm) {
          await queryClient.invalidateQueries({
            queryKey: [`edd-documents_${userId}`],
            refetchType: 'all',
          });
          await queryClient.invalidateQueries({
            queryKey: [`flags_${userId}`],
            refetchType: 'all',
          });
          handleModal();
        }
      },
    }
  );

  const handleSelection = (option) => {
    switch (option.value) {
      case 'viewProfile':
        navigate(`/accounts/${userId}`);
        break;
      case 'approve': {
        const data = isEDDForm
          ? { decision: 'approved', flag: 'edd' }
          : { status: 1 };
        approveDocument(data);
        break;
      }
      case 'reject': {
        const rejectModal = isEDDForm ? (
          <RejectEDDDocument
            document={{
              id,
              user_id: userId,
              flag: 'edd',
            }}
          />
        ) : (
          <RejectDocumentModal
            id={id}
            userId={userId}
            documentUrl={documentUrl}
          />
        );
        handleModal(rejectModal);
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <TableItem item={formatDateTime(dateUploaded)} />
      <TableItem item={userProfile} />
      <TableItem item={email} />
      <TableItem item={activity} />
      <TableItem
        item={
          <a
            className="text-jungleGreen cursor-pointer hover:underline"
            href={documentUrl}
            target="_blank"
            rel="noreferrer"
          >
            {documentText}
          </a>
        }
      />
      <TableItem
        item={<Badge className="bg-sun-10 text-sun-100">{status}</Badge>}
      />
      <TableItem
        item={
          <Dropdown
            icon={Kebab}
            onChange={handleSelection}
            options={kebabActions}
          />
        }
      />
    </>
  );
};

export default PendingTasks;
