export const Purge = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7929 19.5L14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464C14.3417 15.9512 14.6583 15.9512 14.8536 16.1464L17.5 18.7929L20.1464 16.1464C20.3417 15.9512 20.6583 15.9512 20.8536 16.1464C21.0488 16.3417 21.0488 16.6583 20.8536 16.8536L18.2071 19.5L20.8536 22.1464C21.0488 22.3417 21.0488 22.6583 20.8536 22.8536C20.6583 23.0488 20.3417 23.0488 20.1464 22.8536L17.5 20.2071L14.8536 22.8536C14.6583 23.0488 14.3417 23.0488 14.1464 22.8536C13.9512 22.6583 13.9512 22.3417 14.1464 22.1464L16.7929 19.5ZM14.0425 13.5651C13.4189 13.8446 12.7276 14 12 14C11.2739 14 10.5839 13.8452 9.96127 13.5668L5.8793 15.4223C5.3438 15.6657 5 16.1996 5 16.7878V17.5C5 18.3284 5.67157 19 6.5 19H11.5C11.7761 19 12 19.2239 12 19.5C12 19.7761 11.7761 20 11.5 20H6.5C5.11929 20 4 18.8807 4 17.5V16.7878C4 15.8075 4.57301 14.9176 5.46549 14.5119L8.92216 12.9407C7.75209 12.0255 7 10.6006 7 9C7 6.23858 9.23858 4 12 4C14.7614 4 17 6.23858 17 9C17 10.5929 16.2551 12.0119 15.0946 12.9275L17.6966 14.0403C17.9505 14.1489 18.0683 14.4427 17.9597 14.6966C17.8511 14.9505 17.5573 15.0683 17.3034 14.9597L14.0425 13.5651ZM12 13C14.2091 13 16 11.2091 16 9C16 6.79086 14.2091 5 12 5C9.79086 5 8 6.79086 8 9C8 11.2091 9.79086 13 12 13Z"
      fill="currentColor"
    />
  </svg>
);
