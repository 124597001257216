import twMerge from '../utils/twMerge';

const ErrorText = ({ className, errored, errorMessage, ...props }) => {
  return errored ? (
    <div className={twMerge('text-left mt-0.5', className)} {...props}>
      <small className="text-valencia">{errorMessage}</small>
    </div>
  ) : null;
};

export default ErrorText;
