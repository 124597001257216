export const Lock = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.95 19C16.7184 20.1411 15.7095 21 14.5 21H5.5C4.11929 21 3 19.8807 3 18.5V11.5C3 10.2905 3.85888 9.28164 5 9.05001V8C5 5.23858 7.23858 3 10 3C12.7614 3 15 5.23858 15 8V9.05001C16.1411 9.28164 17 10.2905 17 11.5V13H18.5C19.8807 13 21 14.1193 21 15.5V16.5C21 17.8807 19.8807 19 18.5 19H16.95ZM15.9146 19H11.5C10.1193 19 9 17.8807 9 16.5V15.5C9 14.1193 10.1193 13 11.5 13H16V11.5C16 10.7105 15.39 10.0634 14.6157 10.0044C14.5785 10.0132 14.5398 10.0178 14.5 10.0178C14.454 10.0178 14.4095 10.0116 14.3672 10H5.5C4.67157 10 4 10.6716 4 11.5V18.5C4 19.3284 4.67157 20 5.5 20H14.5C15.1531 20 15.7087 19.5826 15.9146 19ZM6 9H14V8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8V9ZM20 16.5V15.5C20 14.6716 19.3284 14 18.5 14H11.5C10.6716 14 10 14.6716 10 15.5V16.5C10 17.3284 10.6716 18 11.5 18H18.5C19.3284 18 20 17.3284 20 16.5ZM11.5 15H12.5C12.7761 15 13 15.2239 13 15.5V16.5C13 16.7761 12.7761 17 12.5 17H11.5C11.2239 17 11 16.7761 11 16.5V15.5C11 15.2239 11.2239 15 11.5 15ZM14.5 15H15.5C15.7761 15 16 15.2239 16 15.5V16.5C16 16.7761 15.7761 17 15.5 17H14.5C14.2239 17 14 16.7761 14 16.5V15.5C14 15.2239 14.2239 15 14.5 15ZM17.5 15H18.5C18.7761 15 19 15.2239 19 15.5V16.5C19 16.7761 18.7761 17 18.5 17H17.5C17.2239 17 17 16.7761 17 16.5V15.5C17 15.2239 17.2239 15 17.5 15Z"
        fill="currentColor"
      />
    </svg>
  );
};
