import { Ascending } from '../../utils/assets/Ascending';
import { Descending } from '../../utils/assets/Descending';

export type TableColumn = {
  label: string;
  field: string;
  showSortIcon: boolean;
};

export type SortDirection = 'asc' | 'desc';

export type TableHeaderProps = {
  columns: TableColumn[];
  onSort?: (field: string, direction: SortDirection) => void;
};

const TableHeader = ({ columns, onSort }: TableHeaderProps) => {
  const isActionOrAllColumn = columns.some((column) =>
    ['Action', 'All'].includes(column.label)
  );

  return (
    <>
      {columns.map((column, index) => (
        <div
          className={`flex items-center font-bold p-4 border-b-0.5 border-loblolly ${
            isActionOrAllColumn && ['Action', 'All'].includes(column.label)
              ? 'col-start-[-2] col-end-[-1]'
              : ''
          }`}
          key={index}
        >
          <span
            className={
              isActionOrAllColumn && ['Action', 'All'].includes(column.label)
                ? 'ml-auto'
                : ''
            }
          >
            {column.label}
          </span>
          {column.showSortIcon && (
            <span className="ml-3 flex flex-col">
              <span
                className="mb-1 cursor-pointer"
                onClick={() => onSort && onSort(column.field, 'asc')}
              >
                <Ascending />
              </span>
              <span
                className="mb-1 cursor-pointer"
                onClick={() => onSort && onSort(column.field, 'desc')}
              >
                <Descending />
              </span>
            </span>
          )}
        </div>
      ))}
    </>
  );
};

export default TableHeader;
