export const Ascending = () => {
  return (
    <svg
      width="10"
      height="5"
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 5L5 0L10 5H0Z" fill="#313C52" />
    </svg>
  );
};
