import { format } from 'date-fns';

type DateInput = string | Date | null;

export const formatDateForDigest = (date: DateInput): string => {
  if (date) {
    return format(new Date(date), 'MMM, yyyy');
  }
  return 'N/A';
};

export const monthDayYear = (date: DateInput): string =>
  date ? format(new Date(date), 'MMMM d, yyyy') : 'N/A';

export const monthAndYear = (date: DateInput): string =>
  date ? format(new Date(date), 'MMMM yyyy') : 'N/A';

export const formatDateForFundCycleList = (date: DateInput): string => {
  if (date) {
    return format(new Date(date), 'dd-MM-yyyy');
  }
  return 'N/A';
};

export const convertToEditableDate = (date: DateInput): string => {
  if (date) {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  return 'N/A';
};
