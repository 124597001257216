import { createContext, useContext, useState } from 'react';

import Modal from '../components/modal/Modal';

export const ModalContext = createContext();

const body = document.querySelector('body');

/**
 * ModalProvider
 * @param {Object} props props
 * @returns jsx
 */
const ModalProvider = ({ children }) => {
  let [modal, setModal] = useState(false);
  let [modalContent, setModalContent] = useState(null);

  /**
   * handleModal
   * @param {JSX} content of the modal or false when there is no content
   */
  const handleModal = (content = false) => {
    setModal(!modal);
    if (content) {
      body.classList.add('disable-scroll');
      setModalContent(content);
    } else {
      body.classList.remove('disable-scroll');
      setModal(false);
    }
  };

  return (
    <ModalContext.Provider value={{ modal, handleModal, modalContent }}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};

/**
 * useModal hook
 * @returns Function
 */
const useModal = () => useContext(ModalContext);
export { ModalProvider, useModal };
