import { createContext, useContext, useState } from 'react';
const PromptContext = createContext({
  prompts: {},
  openPrompt: () => {},
  closePrompt: () => {},
});
const PromptProvider = ({ children }) => {
  const [prompts, setPrompts] = useState({});

  const openPrompt = (id) => {
    setPrompts((prevPrompts) => ({
      ...prevPrompts,
      [id]: true,
    }));
  };

  const closePrompt = (id) => {
    setPrompts((prevPrompts) => ({
      ...prevPrompts,
      [id]: false,
    }));
  };
  return (
    <PromptContext.Provider value={{ prompts, openPrompt, closePrompt }}>
      {children}
    </PromptContext.Provider>
  );
};
const usePrompt = () => {
  return useContext(PromptContext);
};
export { PromptProvider, usePrompt };
