import { useAuth } from '@/context/Auth';
import { useAppQuery } from '@/hooks/useAppQuery';

export const usePermissions = () => {
  const { auth } = useAuth();
  const { data, refetch, isLoading } = useAppQuery(
    'user-permissions',
    {
      url: `v1/roles/permissions/${auth?.user?.id}`,
    },
    { enabled: !!auth?.user?.id }
  );

  const userPermission = data?.data || [];
  const isSuperAdmin = userPermission.includes('*:*:*');

  const hasPermission = (requiredPermissions) => {
    if (!auth?.loggedIn || !userPermission.length) {
      return false;
    }

    if (isSuperAdmin) return true;

    if (!Array.isArray(requiredPermissions)) return false;

    return requiredPermissions.some((reqPerm) =>
      userPermission.some((userPerm) => {
        const [userResource, userAction, userScope] = userPerm.split(':');
        const [reqResource, reqAction, reqScope] = reqPerm.split(':');

        return (
          (userResource === '*' || userResource === reqResource) &&
          (userAction === '*' || userAction === reqAction) &&
          (userScope === '*' || userScope === reqScope)
        );
      })
    );
  };

  return {
    hasPermission,
    permissions: userPermission,
    isLoading,
    refetchPermissions: refetch,
    isSuperAdmin,
  };
};

export default usePermissions;
