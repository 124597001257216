import { format } from 'date-fns';

export const formatStatementDate = (
  date: string | null | undefined
): string => {
  if (!date || date === 'Invalid date') return 'N/A';
  try {
    const dateValue = new Date(date);
    if (isNaN(dateValue.getTime())) return 'N/A';
    return format(dateValue, 'dd LLL yyyy');
  } catch {
    return 'N/A';
  }
};

export const formatDateTime = (datetime: string | null | undefined): string => {
  if (!datetime || datetime === 'Invalid date') return 'N/A';
  try {
    const date = new Date(datetime);
    if (isNaN(date.getTime())) return 'N/A';
    return format(date, 'dd-MMM-yyyy hh:mmaaa');
  } catch {
    return 'N/A';
  }
};

export const formatDuration = (value: number, unit: string): string => {
  return Number(value) > 1 ? `${unit}s` : unit;
};
