import type { ComponentPropsWithoutRef } from 'react';

const Delete = (props: ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.668 4V12.3333C12.668 13.2538 11.9218 14 11.0013 14H5.0013C4.08083 14 3.33464 13.2538 3.33464 12.3333V4H3.0013C2.81721 4 2.66797 3.85076 2.66797 3.66667C2.66797 3.48257 2.81721 3.33333 3.0013 3.33333H6.0013V3C6.0013 2.44772 6.44902 2 7.0013 2H9.0013C9.55359 2 10.0013 2.44772 10.0013 3V3.33333H13.0013C13.1854 3.33333 13.3346 3.48257 13.3346 3.66667C13.3346 3.85076 13.1854 4 13.0013 4H12.668ZM4.0013 4V12.3333C4.0013 12.8856 4.44902 13.3333 5.0013 13.3333H11.0013C11.5536 13.3333 12.0013 12.8856 12.0013 12.3333V4H4.0013ZM9.33463 3.33333V3C9.33463 2.81591 9.1854 2.66667 9.0013 2.66667H7.0013C6.81721 2.66667 6.66797 2.81591 6.66797 3V3.33333H9.33463ZM9.33463 6.33333C9.33463 6.14924 9.48387 6 9.66797 6C9.85206 6 10.0013 6.14924 10.0013 6.33333V11C10.0013 11.1841 9.85206 11.3333 9.66797 11.3333C9.48387 11.3333 9.33463 11.1841 9.33463 11V6.33333ZM6.0013 6.33333C6.0013 6.14924 6.15054 6 6.33464 6C6.51873 6 6.66797 6.14924 6.66797 6.33333V11C6.66797 11.1841 6.51873 11.3333 6.33464 11.3333C6.15054 11.3333 6.0013 11.1841 6.0013 11V6.33333Z"
        fill="#283445"
      />
    </svg>
  );
};

export default Delete;
