import { useState } from 'react';
import { InputField } from '../Input';
import ModalBase from '../modal/ModalBase';
import { NewButton } from '../Button';
import NewCheckbox from '../NewCheckbox';
import { useAppMutation } from '../../hooks/useAppQuery';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from '../../helpers/toastify.helpers';
import { useModal } from '../../context/ModalContext';

const RejectEDDDocument = ({ document }) => {
  const { flag, user_id: userId, id } = document;
  const queryClient = useQueryClient();
  const { handleModal } = useModal();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [reason, setReason] = useState('');

  const { mutate: rejectDocument } = useAppMutation(
    {
      url: `/v1/users/${userId}/edd`,
      method: 'PATCH',
      data: {
        decision: 'rejected',
        reason,
        flag,
      },
    },
    {
      onSettled: async (_, error) => {
        if (error) {
          const { message } = error;
          Toast({
            message,
            type: 'error',
          });
          handleModal();
          return;
        }

        Toast({
          message: `Document Rejected`,
          type: 'success',
        });

        await queryClient.invalidateQueries({
          queryKey: [`edd-documents_${userId}`],
          refetchType: 'all',
        });
        await queryClient.invalidateQueries({
          queryKey: [`pending-tasks`],
          refetchType: 'all',
        });
        handleModal();
      },
    }
  );
  return (
    <ModalBase title="Reject Document">
      <section>
        <h2>You are about to reject this EDD form</h2>
        <div className="my-4">
          <a
            href={`/edd?userId=${userId}&eddId=${id}`}
            className="border border-loblolly block text-center text-16 text-jungleGreen p-2 my-4 rounded"
            rel="noreferrer"
            target="_blank"
          >
            View Document
          </a>
        </div>

        <div className="my-6 space-y-1">
          <label className="text-blueWood">Reason for rejection</label>
          <InputField
            type="text"
            placeholder="Give a reason for the rejection"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>
        <div className="my-6">
          <NewCheckbox
            label="Confirm this action"
            checked={isConfirmed}
            onChange={(value) => setIsConfirmed(value)}
          />
        </div>
        <div className="my-10">
          <NewButton
            styles="bg-ebonyclay text-white"
            label="Reject Document"
            disabled={!isConfirmed || !reason}
            handleClick={() => rejectDocument()}
          />
        </div>
      </section>
    </ModalBase>
  );
};

export default RejectEDDDocument;
