import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useMediaQuery } from 'react-responsive';
import { AnalyticsCard } from './AnalyticsCard';

const Statistics = ({ statistics }) => {
  const { users, funds, wallets } = statistics.data || {};
  const stats = [users, funds, wallets]?.map((options) =>
    options?.map((option) => ({ label: option.title, ...option }))
  );

  const [selectedCard, setSelectedCard] = useState(0);

  const handleCardChange = (index) => {
    setSelectedCard(index);
  };

  const isMobile = useMediaQuery({
    maxWidth: 719,
  });

  return (
    <section className="w-full">
      {isMobile ? (
        <>
          <SwipeableViews
            className="-mx-4 mt-8"
            slideClassName="px-4"
            index={selectedCard}
            onChangeIndex={handleCardChange}
          >
            {stats.map((options, key) => (
              <AnalyticsCard key={key} options={options} />
            ))}
          </SwipeableViews>
          <div className="mt-2 mb-8 flex justify-center space-x-1">
            {stats.map((_, index) => (
              <button
                key={index}
                className={`${
                  index === selectedCard
                    ? 'bg-ebonyclay focus:outline-none'
                    : 'bg-loblolly'
                } size-1.5 rounded-full`}
                onClick={() => setSelectedCard(index)}
              />
            ))}
          </div>
        </>
      ) : (
        <section className="flex gap-4 my-8 md:mt-10 overflow-x-auto hide-scroll-bar w-full">
          {stats.map((options, key) => (
            <AnalyticsCard key={key} options={options} />
          ))}
        </section>
      )}
    </section>
  );
};

export default Statistics;
