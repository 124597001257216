import type { User } from '@kudyfinancials/livre';

const sprites = [
  'identicon',
  'initials',
  'bottts',
  'avataaars',
  'micah',
  'pixel-art',
  'open-peeps',
  'fun-emoji',
  'lorelei',
  'lorelei-neutral',
  'notionists',
  'notionists-neutral',
  'personas',
];

const stringToAvatar = (user: User) => {
  const { avatar, fname = 'kudy' } = user;
  const sprite = sprites[(sprites.length * Math.random()) | 0];
  const defaultAvatar = `https://api.dicebear.com/7.x/${sprite}/svg?seed=${fname.toLowerCase()}`;
  return avatar || defaultAvatar;
};

export default stringToAvatar;
