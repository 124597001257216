import twMerge from '@/utils/twMerge';
import type { ReactNode } from 'react';

type TableItemProps = {
  item: ReactNode;
  className?: string;
};

export const TableItem = ({ item, className }: TableItemProps) => (
  <div
    className={twMerge(
      'flex-1 p-4 border-b-0.5 bg-white border-loblolly cursor-pointer',
      className
    )}
  >
    {item}
  </div>
);
