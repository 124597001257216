type KreditState = {
  selectedInfo: string;
};

type KreditAction = { type: string };

export const kreditReducer = (
  state: KreditState,
  action: KreditAction
): KreditState => {
  switch (action.type) {
    case 'SELECT_KREDIT_PROFILES':
      return { selectedInfo: 'kreditProfiles' };
    case 'SELECT_KREDIT_TRANSACTIONS':
      return { selectedInfo: 'Transactions' };
    default:
      return state;
  }
};
