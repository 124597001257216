const Success = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.383 0 0 5.383 0 12C0 18.617 5.383 24 12 24C18.617 24 24 18.617 24 12C24 5.383 18.617 0 12 0ZM10.206 17.299L4.897 12.817L6.303 11.3L10.206 15.201L17.697 7.7L19.102 9.201L10.206 17.299Z"
        fill="#26c07f"
      />
    </svg>
  );
};

export default Success;
